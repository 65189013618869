import _ from 'lodash'
import React from 'react'
import './card-office.scss'
import IconEmail from '../../../../assets/icons/pacificcross_contact-us_icons_email.svg'
import IconFAX from '../../../../assets/icons/pacificcross_contact-us_icons_fax.svg'
import IconLocation from '../../../../assets/icons/pacificcross_contact-us_icons_location.svg'
import IconPhone from '../../../../assets/icons/pacificcross_contact-us_icons_phone.svg'

function CardOffcies({ data }) {
  return (
    <div className="cardOffice__container">
      <span className="cardOffice__type">{_.get(data, 'type')}</span>
      <h2 className="cardOffice__title">{_.get(data, 'name')}</h2>
      <div className="row">
        <div className="col-2 col-md-1">
          <IconLocation width="20" fill="#1C75BC" />
        </div>
        <div className="col-10 col-md-11">
          <p className="cardOffice__tag">{_.get(data, 'address')}</p>
        </div>
        <div className="col-2 col-md-1">
          <IconPhone width="20" fill="#1C75BC" />
        </div>
        <div className="col-10 col-md-11">
          <p className="cardOffice__tag">{_.get(data, 'tel')}</p>
        </div>
        {data.fax && (
          <React.Fragment>
            <div className="col-2 col-md-1">
              <IconFAX width="20" fill="#1C75BC" />
            </div>
            <div className="col-10 col-md-11">
              <p className="cardOffice__tag">{_.get(data, 'fax')}</p>
            </div>
          </React.Fragment>
        )}
        <div className="col-2 col-md-1">
          <IconEmail width="20" fill="#1C75BC" />
        </div>
        <div className="col-10 col-md-11">
          <p className="cardOffice__tag">{_.get(data, 'email')}</p>
        </div>
      </div>
    </div>
  )
}

export default CardOffcies
